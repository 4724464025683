<template>
    <v-app>
    <Alert />
    <v-progress-linear indeterminate v-if="getLoading"></v-progress-linear>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Alert from '@/components/Alert';
export default {
  name: 'App',
  components: {
    Alert
  },
  computed: {
    ...mapGetters(["getLoading"])
  },
  data: () => ({
    //
  }),
};
</script>
