import Vue from 'vue'
import Vuex from 'vuex'
import Api from "@/services/Api";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentConnection: {},
    loading: false,
    currentConnectionPassword: "",
    rfb: {
      moveViewport: false,
      extraKeys: false,
    },
    alert: {
      shown: false,
      type: '',
      message: ''
    }
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getAlert(state) {
      return state.alert
    },
    getCurrentConnection(state) {
      return state.currentConnection
    },
    getCurrentConnectionPassword(state) {
      return state.currentConnectionPassword
    }
  },
  mutations: {
    SET_LOADING: (state, value) => {
      state.loading = value
    },
    SET_CURRENT_CONNECTION: (state, value) => {
      state.currentConnection = value;
    },
    SET_CURRENT_CONNECTION_PASSWORD: (state, value) => {
      state.currentConnectionPassword = value;
    },
    SET_ALERT_VISIBLE(state, value) {
      state.alert.shown = value;
    },
    SET_ALERT(state, payload) {
      state.alert = payload;
    },
  },
  actions: {
    updateAlert({commit}, data) {
      commit('SET_ALERT', data);
    },
    updateAlertVisibility({commit}, value) {
      commit('SET_ALERT_VISIBLE', value);
    },
    initCurrentConnection({commit, rootState}, password) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true);
        commit('SET_CURRENT_CONNECTION_PASSWORD', password);
        Api.initConnection(rootState.currentConnection.uuid).then((response) => {
          resolve(response.data);
          console.log(response.data);
        }).catch((err) => {
          reject(err);
        }).finally(() => {
          commit('SET_LOADING', false);
        });
      })
    },
    stopCurrentConnection({commit, rootState}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true);
        Api.stopConnection(rootState.currentConnection.uuid).then(() => {
          resolve(true)
        }).catch((err) => {
          reject(err);
        }).finally(() => {
          commit('SET_LOADING'. false)
        })
      })
    },
    getConnectionInfo({commit}, uuid) {
      commit('SET_LOADING', true);
      Api.getConnection(uuid).then((response) => {
        commit('SET_CURRENT_CONNECTION', response.data.connections[0]);
        console.log(response.data);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        commit('SET_LOADING', false);
      });
    },
  },
  modules: {
  }
})
