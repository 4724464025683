import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL
export default {
  getConnection(uuid) {
    return axios.get(`/connections?where={"uuid": "${uuid}"}`);
  },
  initConnection(uuid) {
    return axios.post(`/connections/init`, {uuid: uuid});
  },
  stopConnection(uuid) {
    return axios.post('/connections/stop', {uuid: uuid})
  }
}

